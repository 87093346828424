
import { dispatchGetProductsList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import { readProductsList } from '@/store/products/getters';
import ProductsPagination from '@/components/Products/ProductsPagination.vue';
import ProductListCard from '@/components/Products/ProductListCard.vue';

@Component({
  components: {
    ProductGridCard,
    ProductsPagination,
    ProductListCard,
  },
})

export default class ProductByTagList extends Vue {
  public perPage = 10;
  public page = 1;
  public loadingState: any = {
    list: false,
    page: true,
  };
  public productListType = 'grid';
  public productSortType = '-rating';

  public async mounted() {
    this.handleChangePage(1, true);
    this.loadingState.page = false;
  }

  public async handleChangePage(page, force = false) {
    if (this.page != page || force) {
      this.loadingState.list = true;
      this.page = page;
      const rowQuery = `?page=${this.page}&per_page=${this.perPage}`;
      await dispatchGetProductsList(
        this.$store, {data: {
          tag: this.$router.currentRoute.params.tag,
          order_by: this.productSortType,
        }, rowQuery},
      );
      this.loadingState.list = false;
    }
  }
  
  get productsResponse() {
    return readProductsList(this.$store);
  }

  public hadnleChangeOrdering(ordering) {
    if (this.productSortType !== ordering) {
      this.productSortType = ordering;
      this.handleChangePage(1, true);
    }
  }

  get sortTitle() {
    if (this.productSortType === '-rating') {
      return this.$t('По рейтингу');
    } else if (this.productSortType === '-created_at') {
      return this.$t('По дате');
    } else if (this.productSortType === 'price') {
      return this.$t('От дешевых к дорогим');
    } else if (this.productSortType === '-price') {
      return this.$t('От дорогих к дешевым');
    }
    return '';
  }

}
